<template>
  <div>
    <transition name="fade" mode="out-in" appear>
      <div v-if="overlayShow" id="overlay">
        <div id="text">
          <h1 style="font-size: 50px; font-weight: bold">
            2. {{ $t("profile.yourProfile") }}
          </h1>
          <p>{{ $t("profile.finishreg") }}</p>
          <ion-button
            mode="ios"
            type="submit"
            expand="block"
            shape="round"
            color="primary"
            style="margin-top: 15px; height: 3rem"
            @click="overlayShow = false"
            >{{ $t("buttons.continue") }}</ion-button
          >
        </div>
      </div>
      <ion-page v-else>
        <ion-header class="ion-no-border">
          <ion-toolbar style="text-align: center">
            <img src="/assets/logo.png" style="height: 50px" />
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <div style="max-width: 500px; margin: auto; padding-bottom: 100px">
            <div style="text-align: center">
              <h1 style="font-weight: bold; font-size: 40px">
                {{ $t("profile.yourProfile") }}
              </h1>
              <h3>{{ $t("profile.finishreg") }}</h3>
            </div>

            <ProfileEditFormSkeleton v-if="loading" />
            <div v-else>
              <ProfileEditForm v-if="profile != null" />
              <div v-else style="text-align: center; margin: 2rem">
                <ion-icon :icon="alertCircleOutline" size="large"></ion-icon>
                <h6 style="margin-top: 6px">Nastal problém...</h6>
              </div>
            </div>

            <div style="padding-top: 45px; padding-bottom: 45px">
              <ion-button
                @click="saveAndContinue()"
                mode="ios"
                type="submit"
                expand="block"
                shape="round"
                string="true"
                color="primary"
                style="margin: 15px 0px; height: 3rem"
                >{{ $t("buttons.finish") }}</ion-button
              >
            </div>
          </div>
        </ion-content>
      </ion-page>
    </transition>
  </div>
</template>

<script lang="ts">
import {
  IonPage,
  IonToolbar,
  IonIcon,
  IonContent,
  IonHeader,
  IonButton,
  loadingController,
  toastController,
} from "@ionic/vue";
import {
  alertCircleOutline,
  informationCircleOutline,
  briefcaseOutline,
  locationOutline,
  homeOutline,
  schoolOutline,
  personOutline,
  cameraOutline,
  imageOutline,
  arrowBackOutline,
} from "ionicons/icons";
import { ref } from "vue";

import router from "@/router";
import {
  myProfile,
  loadingMyProfile,
  loadMyProfile,
  saveMyProfile,
  validateProfileIsNok,
  validationOn,
} from "@/components/profile/";

import ProfileEditForm from "@/components/profile/edit/ProfileEditForm.vue";
import ProfileEditFormSkeleton from "@/components/profile/edit/ProfileEditFormSkeleton.vue";
import { useI18n } from "vue-i18n";
import { user } from "@/auth";

export default {
  name: "RegProfile",
  components: {
    IonPage,
    IonToolbar,
    IonIcon,
    IonContent,
    IonHeader,
    ProfileEditForm,
    ProfileEditFormSkeleton,
    IonButton,
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const overlayShow = ref<boolean>(true);
    const loading = ref(loadingMyProfile);
    const profile = ref(myProfile);

    loadMyProfile();
    let savingLoader: any;
    const presentLoader = async () => {
      savingLoader = await loadingController.create({
        message: t("profile.saving"),
      });

      await savingLoader.present();
    };

    const openErrorToast = async (msg: string) => {
      const toast = await toastController.create({
        message: msg,
        color: "danger",
        position: "bottom",
        buttons: [
          {
            text: t("buttons.cancel"),
            role: "cancel",
          },
        ],
      });
      return toast.present();
    };

    const saveAndContinue = async () => {
      if (validateProfileIsNok()) {
        validationOn.value = true;
        document.getElementById("mainInfo")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
        return;
      }

      await presentLoader();
      const { result, error } = await saveMyProfile();
      savingLoader.dismiss();
      if (result == "success") {
        // TODO
        // const allowedUsers = [
        //   "3IzTgLkdV7ZwuKU4bPfEgrsTHGp2",
        //   "ByD2w0e6N3YTAHeBuL9Qw7Xa7oO2",
        //   "k4rbNyfy5qWB2fAz24iAQfyxaiH3",
        // ];
        // if (!allowedUsers.includes(user.value.uid)) {
        //   router.replace("/laska");
        // } else {
        //   }
          router.replace("/my-account");
      } else {
        if (error == "storage") {
          await openErrorToast(t("profile.savingFailedPhoto"));
          // TODO
          // const allowedUsers = [
          //   "3IzTgLkdV7ZwuKU4bPfEgrsTHGp2",
          //   "ByD2w0e6N3YTAHeBuL9Qw7Xa7oO2",
          //   "k4rbNyfy5qWB2fAz24iAQfyxaiH3",
          // ];
          // if (!allowedUsers.includes(user.value.uid)) {
          //   router.replace("/laska");
          // } else {
          //   }
            router.replace("/my-account");
        } else if (error == "database") {
          await openErrorToast(t("profile.savingFailed"));
        }
      }
    };

    return {
      // REFERENCES
      overlayShow,
      profile,
      // validationProfileFail,
      loading,
      saveAndContinue,
      // ICONS
      alertCircleOutline,
      informationCircleOutline,
      briefcaseOutline,
      homeOutline,
      locationOutline,
      schoolOutline,
      personOutline,
      cameraOutline,
      imageOutline,
      arrowBackOutline,
    };
  },
};
</script>

<style scoped>
#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 2;
}

#text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 350px;
  text-align: center;
  font-size: 30px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}
</style>