
import {
  IonPage,
  IonToolbar,
  IonIcon,
  IonContent,
  IonHeader,
  IonButton,
  loadingController,
  toastController,
} from "@ionic/vue";
import {
  alertCircleOutline,
  informationCircleOutline,
  briefcaseOutline,
  locationOutline,
  homeOutline,
  schoolOutline,
  personOutline,
  cameraOutline,
  imageOutline,
  arrowBackOutline,
} from "ionicons/icons";
import { ref } from "vue";

import router from "@/router";
import {
  myProfile,
  loadingMyProfile,
  loadMyProfile,
  saveMyProfile,
  validateProfileIsNok,
  validationOn,
} from "@/components/profile/";

import ProfileEditForm from "@/components/profile/edit/ProfileEditForm.vue";
import ProfileEditFormSkeleton from "@/components/profile/edit/ProfileEditFormSkeleton.vue";
import { useI18n } from "vue-i18n";
import { user } from "@/auth";

export default {
  name: "RegProfile",
  components: {
    IonPage,
    IonToolbar,
    IonIcon,
    IonContent,
    IonHeader,
    ProfileEditForm,
    ProfileEditFormSkeleton,
    IonButton,
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const overlayShow = ref<boolean>(true);
    const loading = ref(loadingMyProfile);
    const profile = ref(myProfile);

    loadMyProfile();
    let savingLoader: any;
    const presentLoader = async () => {
      savingLoader = await loadingController.create({
        message: t("profile.saving"),
      });

      await savingLoader.present();
    };

    const openErrorToast = async (msg: string) => {
      const toast = await toastController.create({
        message: msg,
        color: "danger",
        position: "bottom",
        buttons: [
          {
            text: t("buttons.cancel"),
            role: "cancel",
          },
        ],
      });
      return toast.present();
    };

    const saveAndContinue = async () => {
      if (validateProfileIsNok()) {
        validationOn.value = true;
        document.getElementById("mainInfo")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
        return;
      }

      await presentLoader();
      const { result, error } = await saveMyProfile();
      savingLoader.dismiss();
      if (result == "success") {
        // TODO
        // const allowedUsers = [
        //   "3IzTgLkdV7ZwuKU4bPfEgrsTHGp2",
        //   "ByD2w0e6N3YTAHeBuL9Qw7Xa7oO2",
        //   "k4rbNyfy5qWB2fAz24iAQfyxaiH3",
        // ];
        // if (!allowedUsers.includes(user.value.uid)) {
        //   router.replace("/laska");
        // } else {
        //   }
          router.replace("/my-account");
      } else {
        if (error == "storage") {
          await openErrorToast(t("profile.savingFailedPhoto"));
          // TODO
          // const allowedUsers = [
          //   "3IzTgLkdV7ZwuKU4bPfEgrsTHGp2",
          //   "ByD2w0e6N3YTAHeBuL9Qw7Xa7oO2",
          //   "k4rbNyfy5qWB2fAz24iAQfyxaiH3",
          // ];
          // if (!allowedUsers.includes(user.value.uid)) {
          //   router.replace("/laska");
          // } else {
          //   }
            router.replace("/my-account");
        } else if (error == "database") {
          await openErrorToast(t("profile.savingFailed"));
        }
      }
    };

    return {
      // REFERENCES
      overlayShow,
      profile,
      // validationProfileFail,
      loading,
      saveAndContinue,
      // ICONS
      alertCircleOutline,
      informationCircleOutline,
      briefcaseOutline,
      homeOutline,
      locationOutline,
      schoolOutline,
      personOutline,
      cameraOutline,
      imageOutline,
      arrowBackOutline,
    };
  },
};
